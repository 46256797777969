<template>
  <div class="shadow-md relative">
    <header class="p-3 constrained flex justify-between items-center">
      <img class="h-6 ml-4" alt="Tozny logo" src="/assets/main-logo.svg" />
      <section class="text-right">
        <div class="text-tiny">{{username}}</div>
        <a href="#!" class="text-tozny text-tiny no-underline" @click.prevent="$emit('logout')">Log Out</a>
      </section>
    </header>
  </div>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    username: String,
  }
}
</script>
