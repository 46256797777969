<template>
  <section id="main-footer" class="bg-gray-darkest h-full">
    <div id="pre-footer"></div>
    <div class="text-white py-4 constrained flex justify-between">
      <div class="px-4">© {{ currentYear }} Tozny - All Rights Reserved</div>
      <div class="flex justify-center items-center text-tiny">Powered by
        <span class="sr-only">Tozny</span>
        <svg class="inline h-5 w-16 ml-1">
          <use href="#tozny-logo" />
        </svg>
      </div>
    </div>
    <div id="post-footer"></div>
  </section>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    currentYear: function() {
      const currentDate = new Date()
      return currentDate.getFullYear()
    },
  },
}
</script>
