<template>
  <ContentCard title="Update Password">
    <div class="p-4">
      <ToznyForm
        :onSubmit="btnChangePassword"
        :loading="loading"
        :error="formError"
        class="login-form"
      >
        <p v-if="success" class="text-success text-tiny">
          Password successfully updated
        </p>
        <ToznyInput
          v-model="oldPassword"
          type="password"
          id="old-password"
          name="old-password"
          class="mb-8 text-lil"
          label="Current Password"
        />
        <ToznyInput
          v-model="newPassword"
          type="password"
          id="new-password"
          name="new-password"
          class="mb-2 text-lil"
          label="New Password"
        />
        <ToznyInput
          v-model="confirmPassword"
          type="password"
          id="confirm-password"
          name="confirm-password"
          class="mb-6 text-lil"
          label="Confirm Password"
        />
        <ToznyButton
          id="login-button"
          class="login-button w-full py-3 rounded-lg text-lil mb-0"
          :loading="loading"
          buttonText="Update Password"
        />
      </ToznyForm>
    </div>
  </ContentCard>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import ContentCard from '@/Common/ContentCard'
import ToznyButton from '@/Common/ToznyButton'
import ToznyForm from '@/Common/ToznyForm'
import ToznyInput from '@/Common/ToznyInput'
import { validatePassword } from '@/utils/utils'

export default {
  name: 'ChangePassword',
  components: {
    ContentCard,
    ToznyButton,
    ToznyForm,
    ToznyInput,
  },
  data() {
    return {
      oldPassword: '',
      displayError: '',
      newPassword: '',
      confirmPassword: '',
      success: false,
      loading: false,
    }
  },
  computed: {
    ...mapState(['realmName', 'identity']),
    ...mapGetters(['realm']),
    formError: function() {
      if (this.oldPasswordError) {
        return this.oldPasswordError
      } else if (this.validatePasswordError) {
        return this.validatePasswordError
      } else if (this.matchingPasswordError) {
        return this.matchingPasswordError
      }
      return this.displayError
    },
    oldPasswordError: function() {
      if (this.newPassword && !this.oldPassword) {
        return 'You must supply your current password'
      }
      if (this.newPassword && this.oldPassword == this.newPassword) {
        return 'New password can not be the same the current password'
      }
      return ''
    },
    validatePasswordError: function() {
      if (this.newPassword) {
        return validatePassword(this.newPassword)
      }
      return ''
    },
    matchingPasswordError: function() {
      if (this.newPassword !== this.confirmPassword) {
        return 'Passwords do not match'
      }
      return ''
    },
  },
  methods: {
    async btnChangePassword() {
      this.success = false
      this.loading = true
      if (
        !this.newPassword ||
        this.oldPasswordError ||
        this.validatePasswordError ||
        this.matchingPasswordError
      ) {
        this.loading = false
        return
      }
      try {
        await this.identity.updatePassword(this.oldPassword, this.newPassword)
      } catch (e) {
        this.displayError = e.message
        this.loading = false
        return
      }
      this.oldPassword = ''
      this.newPassword = ''
      this.confirmPassword = ''
      this.loading = false
      this.success = true
    },
  },
}
</script>
