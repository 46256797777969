<template>
  <MainLayout>
    <section class="grid">
      <div>
        <ChangePassword />
      </div>
      <div>
        <MFA />
      </div>
    </section>
  </MainLayout>
</template>

<script>
import MainLayout from '@/Common/MainLayout/MainLayout'
import ChangePassword from './Components/ChangePassword'
import MFA from './Components/MFA'
export default {
  name: 'Security',
  components: {
    MainLayout,
    ChangePassword,
    MFA,
  },
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 2em;
}
</style>
