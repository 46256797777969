<template>
  <ContentCard title="Muti-Factor Authentication">
    <Banner
      v-if="hasError && !isIdle"
      type="red"
      button-text="Reload"
      :click-method="reload"
    >
      {{ errorMessage }}
    </Banner>
    <div v-if="isIdle" class="p-4">
      <div v-if="type === 'totp'">
        <Banner
          class="inline-banner"
          button-text="Remove"
          :click-method="removeMFA"
        >
          Your account is protected with a mobile authentiator.
        </Banner>
      </div>
      <div v-if="type === 'push'">
        <Banner
          class="inline-banner"
          button-text="Remove"
          :click-method="removeMFA"
        >
          Your account is protected with the TozID Authenticator.
        </Banner>
      </div>
      <div v-else-if="type === 'none'">
        <div class="flex" v-if="mfaOptions.includes('ToznyPush')">
          <h4 class="font-semibold text-base">
            Configure Authenticator Application
          </h4>
          <ToznyForm :onSubmit="toggleMFASetup">
            <ToznyButton
              id="toggle-mfa-setup-button"
              class="py-1 rounded-lg text-lil mx-5 min-w-0"
              :buttonText="mfaSwitchDisplayName"
            />
          </ToznyForm>
        </div>
        <ul class="flex border-b border-gray-medium mb-4">
          <li v-if="setupType === 'push'">
            <a
              href="#!"
              class="block border border-gray-medium px-2 py-1 -mb-px mx-1 no-underline text-gray-dark"
              :class="{ active: qrTab === 'push' }"
              @click="qrTab = 'push'"
            >
              Push
            </a>
          </li>

          <li v-if="setupType === 'totp'">
            <a
              href="#!"
              class="block border border-gray-medium px-2 py-1 -mb-px mx-1 no-underline text-gray-dark"
              :class="{ active: qrTab === 'scan' }"
              @click="qrTab = 'scan'"
            >
              Scan
            </a>
          </li>
          <li v-if="setupType === 'totp'">
            <a
              href="#!"
              class="block border border-gray-medium px-2 py-1 -mb-px mx-1 no-underline text-gray-dark"
              :class="{ active: qrTab === 'manual' }"
              @click="qrTab = 'manual'"
            >
              Manual
            </a>
          </li>
        </ul>
        <div v-if="setupType === 'push'">
          <div v-if="qrTab === 'push'">
            <p>
              1. Download the TozID Authenticator [<a
                href="https://apps.apple.com/us/app/tozid-authenticator/id1498498814"
                >iOS</a
              >|<a
                href="https://play.google.com/store/apps/details?id=com.tozny.tozidauthenticator"
                >Android</a
              >]
            </p>
            <p>2. Press the button below to generate your QR Code</p>
            <p>3. Scan and finish setup on the app</p>
            <div
              v-if="pushQRReady"
              v-html="pushQRCodeImg"
              class="mx-auto my-4 w-48 h-48"
            ></div>
            <ToznyForm
              :onSubmit="mobilePushSetup"
              :loading="status === 'idle.submitting'"
              :error="errorMessage"
            >
              <ToznyButton
                id="otp-button"
                class="w-full py-3 rounded-lg text-lil mb-0"
                buttonText="Setup Push"
                :loading="status === 'idle.submitting'"
              />
            </ToznyForm>
          </div>
        </div>
        <div v-if="setupType === 'totp'">
          <div v-if="qrTab === 'scan'">
            <p>1. Scan this code in your authenticator application</p>
            <div v-html="qrCodeImg" class="mx-auto my-4 w-48 h-48"></div>
            <p>2. Enter the one-time code to finish set up</p>
            <ToznyForm
              :onSubmit="totpSetup"
              :loading="status === 'idle.submitting'"
              :error="errorMessage"
            >
              <ToznyInput
                v-model="otp"
                type="tel"
                title="Numbers only"
                pattern="[0-9]+"
                id="otp"
                name="otp"
                class="mb-6 text-lil"
                label="One-time Code"
                float-label
              />
              <ToznyButton
                id="otp-button"
                class="w-full py-3 rounded-lg text-lil mb-0"
                buttonText="Submit"
                :loading="status === 'idle.submitting'"
              />
            </ToznyForm>
          </div>
          <div v-else-if="qrTab === 'manual'">
            <p>1. Configure your app using this secret key</p>
            <dl class="mb-4 ml-4 p-0 text-lil">
              <dt class="sr-only">Secret Key:</dt>
              <dd class="mt-2 mb-4 text-center text-base tracking-wider">
                <div class="inline-block px-2 py-1 border border-gray-medium">
                  {{ secret }}
                </div>
              </dd>
              <div v-for="conf in otpManualSetupConfig" v-bind:key="conf.title">
                <dt class="inline-block mr-2">{{ conf.title }}:</dt>
                <dd class="inline-block font-bold mb-1 inline-block">
                  {{ conf.data }}
                </dd>
              </div>
            </dl>
            <p>2. Enter the one-time code to finish set up</p>
            <ToznyForm
              :onSubmit="totpSetup"
              :loading="status === 'idle.submitting'"
              :error="errorMessage"
            >
              <ToznyInput
                v-model="otp"
                type="tel"
                title="Numbers only"
                pattern="[0-9]+"
                id="otp"
                name="otp"
                class="mb-6 text-lil"
                label="One-time Code"
                float-label
              />
              <ToznyButton
                id="otp-button"
                class="w-full py-3 rounded-lg text-lil mb-0"
                buttonText="Submit"
                :loading="status === 'idle.submitting'"
              />
            </ToznyForm>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="status === 'loading'"
      class="w-full h-32 flex items-center justify-center"
    >
      <ToznyLoader class="text-tozny w-12 h-12" />
    </div>
  </ContentCard>
</template>

<script>
import Banner from '@/Common/Banner'
import ContentCard from '@/Common/ContentCard'
import ToznyForm from '@/Common/ToznyForm'
import ToznyInput from '@/Common/ToznyInput'
import ToznyButton from '@/Common/ToznyButton'
import ToznyLoader from '@/Common/ToznyLoader'

import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'MFA',
  components: {
    Banner,
    ContentCard,
    ToznyForm,
    ToznyInput,
    ToznyButton,
    ToznyLoader,
  },
  data: function() {
    return {
      qrTab: 'scan',
      setupType: 'totp',
      mfaSwitchDisplayName: 'Push',
      otp: '',
      poll: false,
    }
  },
  computed: {
    ...mapState('mfa', ['status', 'errorMessage', 'type', 'secret', 'policy']),
    ...mapState(['mfaOptions']),
    ...mapGetters('mfa', [
      'hasError',
      'isIdle',
      'qrCodeImg',
      'pushQRCodeImg',
      'pushQRReady',
    ]),
    otpManualSetupConfig() {
      if (
        typeof this.policy !== 'object' ||
        Object.keys(this.policy).length < 1
      ) {
        return []
      }
      return [
        {
          title: 'Type',
          data: this.policy.type === 'totp' ? 'Time Based' : 'Counter Based',
        },
        {
          title: 'Algorithm',
          data: this.policy.algorithmKey,
        },
        {
          title: 'Digits',
          data: this.policy.digits,
        },
        {
          title: 'Interval',
          data: this.policy.period,
        },
      ]
    },
  },
  methods: {
    ...mapActions('mfa', [
      'initialize',
      'setupTotp',
      'removeMFA',
      'reload',
      'setupMobilePush',
      'checkMFASetup',
    ]),
    totpSetup() {
      this.setupTotp(this.otp)
      this.otp = ''
    },
    mobilePushSetup() {
      this.setupMobilePush()
      this.doMobilePoll()
    },
    toggleMFASetup() {
      if (this.qrTab === 'push') {
        this.qrTab = 'scan'
        this.setupType = 'totp'
        this.mfaSwitchDisplayName = 'Push'
      } else {
        this.qrTab = 'push'
        this.setupType = 'push'
        this.mfaSwitchDisplayName = 'TOTP'
      }
    },
    async doMobilePoll() {
      this.poll = setInterval(async () => {
        let mfa = await this.checkMFASetup()
        if (mfa) {
          this.clearPoll()
        }
      }, 3000)
    },
    clearPoll() {
      if (this.poll) {
        clearInterval(this.poll)
      }
      this.poll = false
    },
  },
  created: async function() {
    await this.initialize()
  },
  beforeDestroy() {
    this.clearPoll()
  },
}
</script>

<style scoped>
.active {
  border-bottom-color: #ffffff;
  color: #2f353a;
}
.inline-banner {
  margin-bottom: 0;
}
</style>
