<template>
  <nav class="font-display1 px-6">
    <ul class="w-40">
      <NavItem
        text="Profile"
        :path="`/${realmName}`"
        icon="person"
        name="profile"
      />
      <NavItem
        text="Security"
        :path="`/${realmName}/security`"
        icon="lock"
        name="security"
      />
      <NavItem
        text="Applications"
        :path="`/${realmName}/applications`"
        icon="apps"
        name="applications"
      />
      <NavItem
        text="Sessions"
        :path="`/${realmName}/sessions`"
        icon="timer"
        name="sessions"
      />
      <NavItem
        text="Account App"
        :path="`${appRoot}/auth/realms/${realmName}/account/applications`"
        icon="launch"
        name="account app"
      />
      <NavItem
        v-if="secretActive"
        text="Secrets"
        :path="`/${realmName}/secrets`"
        icon="vpn_key"
        name="secrets"
      />
    </ul>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import NavItem from './NavItem'
export default {
  name: 'NavMenu',
  components: {
    NavItem,
  },
  computed: {
    ...mapState('nav', ['tozIDSecretsEnabled']),
    ...mapState(['realmName']),
    appRoot() {
      return global.API_URL
    },
    secretActive() {
      return (
        this.tozIDSecretsEnabled !== false ||
        this.realmName === 'secrettestrealm' ||
        this.realmName === 'toznysso'
      )
    },
  },
   methods: {
    ...mapActions('nav', [
      'toggleTozIDSecretOpen',
    ]),

  },
  created: async function () {
    await this.toggleTozIDSecretOpen()
  },
}
</script>
