<template>
  <div class="min-h-full flex flex-col">
    <TitleBar
      :username="identity ? identity.config.username : ''"
      @logout="logout"
    />
    <div class="flex constrained flex-grow w-full">
      <NavMenu />
      <main class="flex-grow min-w-0">
        <ContentColumn>
          <slot></slot>
        </ContentColumn>
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>
import TitleBar from './TitleBar'
import NavMenu from './NavMenu'
import ContentColumn from '@/Common/ContentColumn'
import Footer from '@/Common/Footer'

import { mapState, mapActions } from 'vuex'
export default {
  name: 'MainLayout',
  components: {
    ContentColumn,
    Footer,
    NavMenu,
    TitleBar,
  },
  computed: {
    ...mapState(['identity']),
  },
  methods: {
    ...mapActions(['logout']),
  },
  beforeMount() {
    document.body.className = `${document.body.className} authenticated`
  },
}
</script>
