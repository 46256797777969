<template>
  <li>
    <component
      v-bind:is="linkType"
      class="flex items-center no-underline py-4 cursor-pointer"
      :href="hrefAttr"
      :to="toAttr"
      :class="activeClass"
      :title="text"
    >
      <i class="material-icons micon">{{ icon }}</i>
      <span class="ml-4">{{text}}</span>
    </component>
  </li>
</template>

<script>
export default {
  name: 'NavItem',
  computed: {
    activeClass() {
      const firstGroup = this.$route.matched.find(r => !!r.meta.menu)
      const active = firstGroup && firstGroup.meta.menu === this.name
      return active ? 'text-gray-darkest' : 'text-gray-dark'
    },
    linkType() {
      return this.path.substring(0,1) === '/' ? 'router-link' : 'a'
    },
    toAttr() {
      return this.linkType === 'a' ? false : this.path
    },
    hrefAttr() {
      return this.linkType === 'a' ? this.path : false
    }
  },
  props: {
    icon: String,
    text: String,
    path: String,
    name: String,
  },
}
</script>
